<template>
	<div
		class="vx-row lg:flex w-full no-gutter lg:h-screen"
		id="page-register"
		>
		<div :class="{'hidden': isRegisterOnly}" class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full">
    <!-- Logo -->
      <div class="absolute logo-wrapper-pre-event mb-4 p-6">
        <img :src="event.logo" alt="Kobla" class="pr-5 xl:block max-w-250" v-if="event.logo && event.logo !== '@/assets/images/logo/blank.png'" />
        <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 xl:block max-w-250" v-if="!event.logo" />
      </div>
			<div class="items-center h-full w-full" :style="colorPage">
        <vs-row v-if="!isRegisterOnly" vs-w="8" class="w-full" style="margin: auto;">
          <div class="video-pre-embed outside-container-left-side-register mt-16">
          <div class="container-left-side-register">
            <pre-live-event-register
              :date="event.start_date"
              :timeZone="event.timezone"
              :show="true"
              :eventSettings="eventSettings"
              :event="event"
              :isFromRegister="true"
              @showCountdown="showCountdown"
              >
            </pre-live-event-register>
          </div>
        </div>
        </vs-row>

			</div>
		</div>

    <!-- if unregister -->
		<div :class="{'lg:w-1/2': !isRegisterOnly}"
      v-if="!isRegisterSuccess && !isShowLinkCode && isRegisterEnabled" :style="backgroundSetting" class="vx-col sm:w-1/1 xs:w-1/1 w-full lg:m-0">
      <div class="flex justify-center w-full container-right-side-register" >
				<div class=" mx-auto login-wrapper w-full mb-10 lg:mb-1">
          <div class="max-w-full">
            <div class="space-y-6">
              <div class="text-4xl text-white">
                {{ $t('Register to attend') }}
              </div>
              <!-- Name -->
              <div>
                <div class="text-white mb-1">
                  {{ $t('Full Name') }}
                </div>
                <vs-input v-filter-input
                  v-validate="'required|min:3'"
                  data-vv-validate-on="blur"
                  name="name"
                  :maxlength="50"
                  :placeholder="$t('Full Name')"
                  v-model="form.name"
                  class="w-full input-lg-transparent-register border-white"
                />
                <span class="text-danger text-sm">{{ $t(errors.first('name')) }}</span>
              </div>

              <!-- Company -->
              <div>
                <div class="text-white mb-1">
                  {{ $t('Company') }}
                </div>
                <vs-input v-filter-input
                  data-vv-validate-on="blur"
                  name="company"
                  :placeholder="$t('Company')"
                  v-model="form.company"
                  class="w-full input-lg-transparent-register"
                />
                <span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
              </div>

              <!-- Job -->
              <div v-if="isEnableExtended">
                <div class="text-white mb-1">
                  {{ $t('Job Position') }}
                </div>
                <vs-input v-filter-input
                  data-vv-validate-on="blur"
                  name="job_position"
                  :placeholder="$t('Job Position')"
                  v-model="form.job_position"
                  class="w-full input-lg-transparent-register"
                />
                <span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
              </div>

              <!-- Email -->
              <div>
                <div class="text-white mb-1">
                  {{ $t('Email') }}
                </div>
                <vs-input v-filter-input-email
                  v-validate="'required|email'"
                  data-vv-validate-on="blur"
                  name="email"
                  type="email"
                  :placeholder="$t('Email')"
                  v-model="form.email"
                  class="w-full input-lg-transparent-register"
                />
                <span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>
              </div>

                <!-- Phone -->
                <div class="mb-5" v-if="isEnableExtended">
                  <div class="text-white mb-1">
                    {{ $t('Phone Number') }}
                  </div>
                  <VuePhoneNumberInput
                  :translations="phoneTranslations"
                  class="input-number transparent-input"
                  :default-country-code="defaultCountryCode"
                  :preferred-countries="['NO', 'US', 'GB']"
                  @update="onUpdatePhone"
                  v-model="form.mobile_phone"
                  :no-validator-state="true"
                  :color="telephoneColor"
                  />
                </div>

              <!-- I'm attending at the venue -->
              <div v-if="isHybrid && isShowCountDown" class="flex py-8">
                <label class="custom-checkbox">
                  <input type="checkbox" v-model="form.is_attending_to_venue" id="checkbox-participant">
                  <span class="checkmark"></span>
                </label>
                <div class="text-white pt-3">
                  <div class="">
                    {{ $t("I'm attending at the venue")}}
                  </div>
                  <div class="text-sm mt-2">
                    {{ $t("Please note that the seats are limited at the venue, we will send a confirmation to your email if you are available for a seat")}}
                  </div>
                </div>
              </div>

              <!-- Description -->
              <div v-if="isShowTC" class="space-y-2 text-white text-sm">
                <div v-if="!dataTC" >
                  <div class="mt-6" :class="{'mt-12': form.is_attending_to_venue}">
                    {{ $t("We must have consent to be able to store personal information. We store your email address and name, and possibly also your place of work and position. We also store which events you have signed up for, and send you information about events on the same topic. We never share the information with others, and we delete all information about you if you send us an email and ask for it.") }}
                  </div>
                  <div>
                    {{ $t("If you do not want information to be stored beyond the registration for this event, all personal information will be deleted from our contact lists, by clicking register you are agree to this terms.") }}
                  </div>
                  <div>
                    <a href="https://personvern.lomedia.no/" target="_blank" class="font-medium underline" style="color: white">{{ $t('Privacy Policy') }}</a> {{ $t('and') }}
                    <a href="https://policies.google.com/terms" target="_blank" class="font-medium underline" style="color: white">{{ $t('Terms of Service') }}</a> {{ $t('apply') }}.
                  </div>
                  <div>
                    {{ $t("This is how we process your data") }}:
                    <a href="https://personvern.lomedia.no/personvernerklaering/" target="_blank" class="font-medium underline" style="color: white">{{ $t("Privacy statement") }}</a>
                  </div>
                </div>
                <div v-else>
                  <div v-html="dataTC" class="mt-6" :class="{'mt-12': form.is_attending_to_venue}">
                  </div>
                </div>
              </div>

              <!-- Notes -->
              <div v-if="isEnableExtended">
                <div v-if="dataNotes && isShowNotes" class="text-white mb-1" v-html="dataNotes">
                </div>
                <div v-if="!isShowNotes" class="text-white mb-1">
                  {{ $t('Notes') }}
                </div>
                <vs-textarea class="input-lg-transparent-register border-white input-border-white" rows="3" v-model="form.notes" :placeholder="dataNotesPlaceholder"/>
              </div>

              <!-- Opt-in email -->
              <div class="flex" v-if="isShowEmailUpdate">
                <label class="custom-checkbox-small">
                  <input type="checkbox" v-model="form.is_subscribe_event_updates" id="checkbox-participant-2">
                  <span class="checkmark-small"></span>
                </label>
                <div class="text-white  ">
                  <div class="text-sm">
                    {{ $t("Keep me updated about this event")}}
                  </div>
                </div>
              </div>

              <div class="flex" v-if="isShowEmailMarketing">
                <label class="custom-checkbox-small">
                  <input type="checkbox" v-model="form.is_subscribe_marketing_emails " id="checkbox-participant-3">
                  <span class="checkmark-small"></span>
                </label>
                <div class="text-white">
                  <div class="text-sm">
                    {{ $t("I agree to receive marketing emails from Kobla")}}
                  </div>
                </div>
              </div>


              <!-- Submit -->
              <div>
                <vs-button @click="register" :disabled="!isFormValid" class="mt-6 mb-4 btn-lg-register w-full">{{$t('Register for the Event')}}</vs-button>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>


    <!-- link code -->
    <div v-if="!isRegisterSuccess && isShowLinkCode" :style="backgroundSetting" class="text-center vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full lg:m-0 p-6">
			<div class="flex justify-center flex margin-a items-center h-full w-full">
				<div class="">
          <div class="max-w-lg">
            <div class="space-y-6">
              <div class="text-white text-3xl font-base text-left">
                  <div>{{ $t('Joining as a participant') }}?</div>
                  <div>{{ $t('No account needed.') }}</div>
                </div>

                <vs-row vs-type="flex" vs-justify="flex-start" class="mt-3">
                  <form-enter-input-code :isFromRegister="true" @refetchLandingPage="refetchLandingPage"/>
                </vs-row>
              <div class="text-white">
                <div class=" text-left pointer text-white" @click="openLinkCode()">{{ $t('Register to this event') }} <span class="underline">{{ $t('here') }}</span></div>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>

    <!-- registered success -->
    <div v-if="isRegisterSuccess && !isShowLinkCode" :style="backgroundSetting" class="text-center vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full lg:m-0 p-6">
			<div class="flex justify-center items-center h-full w-full">
				<div class="px-10 max-w-lg mx-auto login-wrapper w-full">
          <div class="max-w-lg">
            <div class="space-y-6">
              <div class="text-4xl text-white">
                {{ dataMessageSuccessRegistration }}
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>

    <!-- registered closed -->
    <div v-if="!isRegisterSuccess && !isShowLinkCode && !isRegisterEnabled" :style="backgroundSetting" class="text-center vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full lg:m-0 p-6">
			<div class="flex justify-center items-center h-full w-full">
				<div class="px-10 max-w-lg mx-auto login-wrapper w-full">
          <div class="max-w-lg">
            <div class="space-y-6">
              <div class="text-4xl text-white">
                {{ $t('Register are closed , Thank you for your interest in this event') }}
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';
import PreLiveEventRegister from '@/components/PreLiveEventRegister.vue';
import participantApi from '@/api/participant';
import FormEnterInputCode from '@/components/events/FormEnterInputCode.vue';
import { getAxiosErrorMessage, isValidEmail } from '@/lib/helper';
import VuePhoneNumberInput from 'vue-phone-number-input';

dayjs.extend(relativeTime);

export default {
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    dataConfirm: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        company: '',
        is_attending_to_venue: false,
        is_subscribe_marketing_emails: false,
        is_subscribe_event_updates: false,
        notes: '',
        job_position: '',
        mobile_phone: '',
      },
      isRegistering: false,
      isRegisterSuccess: false,
      dataMessageSuccessRegistration: '',
      isShowLinkCode: false,
      isShowCountDown: false,
      resultsPhone: {},
      phoneTranslations: {
        countrySelectorLabel: this.$t('Code Country'),
        countrySelectorError: this.$t('Choose one country'),
        phoneNumberLabel: this.$t('Phone Number'),
        example: this.$t('Example:'),
      },
      defaultCountryCode: 'NO', // default to NO initially
    };
  },
  components: {
    PreLiveEventRegister,
    FormEnterInputCode,
    VuePhoneNumberInput,
  },
  methods: {
    onUpdatePhone(payload) {
      this.resultsPhone = payload;
    },
    showCountdown(value) {
      this.isShowCountDown = value;
    },
    refetchLandingPage(code) {
      this.$emit('refetchLandingPage', code);
    },
    openLinkCode() {
      this.isShowLinkCode = !this.isShowLinkCode;
    },
    register() {
      this.isRegistering = true;
      this.$vs.loading();
      const notifTitle = this.$t('Register');
      const params = {
        name: this.form.name,
        company: this.form.company,
        email: this.form.email,
        notes: this.form.notes,
        is_attending_to_venue: this.form.is_attending_to_venue,
        is_subscribe_marketing_emails: this.form.is_subscribe_marketing_emails,
        is_subscribe_event_updates: this.form.is_subscribe_event_updates,
        job_position: this.form.job_position,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
      };
      const callback = (response) => {
        this.isRegisterSuccess = true;
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.dataMessageSuccessRegistration = message;
        this.isRegistering = false;
        this.$vs.loading.close();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isRegistering = false;
        this.$vs.loading.close();
      };
      participantApi.register(this.event.id, params, callback, errorCallback);
    },
    setBackground() {
      // eslint-disable-next-line
			let bgImage = require('@/assets/images/backgrounds/BG_ALT_04.png');
      if (this.event && this.event.background) bgImage = this.event.background;

      document.body.style.backgroundImage = `url(${bgImage})`;
      // document.body.style.backgroundSize = 'cover';
    },
    addBodyClass() {
      document.body.classList.add('background-repeat');
      this.setBackground();
    },
    async getCountryCode() {
      try {
        const response = await axios.get('https://ip2c.org/s');
        const data = response.data.split(';');
        if (data[0] === '1') {
          this.defaultCountryCode = data[1]; // data[1] contains the country code
        } else {
          console.error('Unable to determine country code.');
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    },
  },
  computed: {
    backgroundSetting() {
      return `background-color: ${this.event && this.event.base_color ? this.event.base_color : '#0454C7'}`;
    },
    telephoneColor() {
      return `${this.event && this.event.base_color ? this.event.base_color : '#0454C7'}`;
    },
    formatEventTime() {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');

      const timeLabel = this.event && this.event.start_date ? dayjs(this.event.start_date).format('dddd DD MMMM YYYY') : '';
      return timeLabel;
    },
    isHybrid() {
      return this.event && this.event.is_hybrid ? this.event.is_hybrid : false;
    },
    isAttend() {
      return this.form.is_attending_to_venue;
    },
    isFormValid() {
      return this.form.name.length > 2 && isValidEmail(this.form.email);
    },
    isRegisterOnly() {
      const settings = this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_registration_only;
      return settings;
    },
    isRegisterEnabled() {
      const settings = this.eventSettings && this.eventSettings.sidebar ? this.eventSettings.sidebar : null;
      // eslint-disable-next-line no-prototype-builtins
      if (settings.hasOwnProperty('is_registration_enabled')) {
        return this.eventSettings.sidebar.is_registration_enabled;
      }
      // Property does not exist
      return true;
    },
    isEnableExtended() {
      const settings = this.eventSettings && this.eventSettings.sidebar && this.eventSettings.sidebar.is_enable_extended_field;
      return settings;
    },
    isShowTC() {
      const isTC = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_tc')) return true;
      return isTC && isTC.is_show_tc ? isTC.is_show_tc : false;
    },
    isShowNotes() {
      const isNotes = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      return isNotes && isNotes.is_show_notes ? isNotes.is_show_notes : false;
    },
    colorPage() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color_event ? this.eventSettings.style.is_dark_color_event : false;
      return `color: ${fontColor ? 'white' : 'black'}`;
    },
    dataTC() {
      const tc = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : '';
      return tc && tc.tc ? tc.tc : '';
    },
    dataNotes() {
      const notes = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : '';
      return notes && notes.note ? notes.note : '';
    },
    isShowNotesPlaceholder() {
      const isNotes = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      return isNotes && isNotes.is_show_notes_placeholder ? isNotes.is_show_notes_placeholder : false;
    },
    dataNotesPlaceholder() {
      const notes = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : '';
      return notes && notes.notes_placeholder && this.isShowNotesPlaceholder ? notes.notes_placeholder : '';
    },
    isShowEmailUpdate() {
      const isEmail = this.eventSettings && this.eventSettings.sidebar ? this.eventSettings.sidebar : false;
      return isEmail && isEmail.is_email_update ? isEmail.is_email_update : false;
    },
    isShowEmailMarketing() {
      const isEmail = this.eventSettings && this.eventSettings.sidebar ? this.eventSettings.sidebar : false;
      return isEmail && isEmail.is_email_marketing ? isEmail.is_email_marketing : false;
    },
  },
  mounted() {
    if (this.dataConfirm) {
      this.dataMessageSuccessRegistration = this.dataConfirm;
      this.isShowLinkCode = false;
      this.isRegisterSuccess = true;
    }
    this.addBodyClass();
  },
  watch: {
    isAttend() {
      if (this.isAttend) this.form.notes = '';
    },
  },
  created() {
    this.getCountryCode();
  },
};
</script>
<style lang="scss">
  .container-left-side-register {
    padding: 6.8em 14em 6.8em 14em;
  }

  .container-right-side-register {
    padding: 10em 14em 6.8em 14em;
  }

  @media (max-width: 1600px) { /* Adjust this value as needed for your design */
    .container-left-side-register {
      padding: 5em 5em 5em 5em; /* Example of adaptive padding for smaller screens */
    }
    .container-right-side-register {
      padding: 8em 5em 5em 5em;
    }
  }

  @media (max-width: 768px) { /* Adjust this value as needed for your design */
    .container-right-side-register {
      padding: 2em; /* Example of adaptive padding for smaller screens */
    }
  }

  @media (max-width: 768px) { /* Adjust this value as needed for your design */
    .container-left-side-register {
      padding: 2em 2em 2em 2em; /* Example of adaptive padding for smaller screens */
    }
  }

  .outside-container-left-side-register {
    height: calc(100vh - 70px); overflow-y: auto; overflow-x: hidden; width: 100%;
  }

  @media (max-width: 991px) { /* Adjust this value as needed for your design */
    .outside-container-left-side-register {
      height: 100%; overflow-y: hidden; overflow-x: hidden; width: 100%;
    }
  }

  .transparent-input .input-tel__input {
    background-color: transparent;
  }

  .transparent-input .country-selector__input {
    background-color: white;
    color: black;
    border-color: white!important;
  }

  .transparent-input .country-selector__label {
    color: black;
  }

  .transparent-input .input-tel__input::placeholder {
    color: rgba(255, 255, 255, 0.5); /* Optional: adjust placeholder color */
  }

  .transparent-input .input-tel .input-tel__input {
    color: white; /* Optional: adjust placeholder color */
    border-color: white!important;
  }

  .transparent-input .input-tel.is-valid .input-tel__input {
    color: white; /* Optional: adjust placeholder color */
    border-color: white!important;
  }

  .transparent-input .input-tel .input-tel__label {
    color: white !important; /* Optional: adjust placeholder color */
  }

  .input-lg-transparent-register {
      .vs-input--input {
          height: 50px;
          background: transparent;
          border-color: white !important;
          color: white;
          padding-left: 20px !important;
      }
      .vs-input--placeholder {
          top: 8px;
          left: 10px;
          color: grey !important;
      }
  }

  a:active, a:visited, a:hover, a {
    color:white !important;
  }
</style>
